import styled, { css } from "styled-components";
import {
  BP,
  Rem,
  Colors,
} from "../../commons/Theme";

import {
  StyledForm,
  StyledFormBlock,
} from "../../commons/Form";

export const StyledBusinessPreviewOasiForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${StyledForm} {
    width: 100%;
  }

  ${StyledFormBlock} {
    @media(${BP.tablet}) {
      max-width: ${Rem(400)};

      &:first-child {
        padding-right: ${Rem(20)};
      }
    }
  }
`;

export const StyledBusinessPreviewOasiFormInputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${Rem(20)};

  @media(${BP.tablet}) {
    flex-direction: row;
  }
`;

export const StyledBusinessPreviewOasiFormDragDropImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  width: 100%;
  max-width: ${(props) => props.isUploaded && !props.isDragActive ? "fit-content" : Rem(660)};
  height: ${Rem(280)};
  border: ${Rem(3)} dashed ${Colors.yellow};
  box-sizing: border-box;

  ${(props) =>
    props.isDragActive
    && css`
      background-color: ${Colors.yellow40};
    `}

  & img {
    height: ${(props) => props.isUploaded ? "100%" : "50%"};
  }
`;
export const StyledBusinessPreviewOasiLogoPlaceholder = styled.div`
  font-weight: 900;
  font-size: ${Rem(25)};
  line-height: ${Rem(40)};
  color: ${Colors.yellow};
`;
