import React, {
  useState, useCallback, useContext, useRef, 
} from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import useApi from "../../hooks/useApi";
import { StyledGridRow } from "../../commons/Grid";
import { DataContext } from "../../contexts/DataContext";
import { TextInput, Button } from "@3beehivetech/components";

import {
  StyledForm,
  StyledFormBlock,
} from "../../commons/Form";

import {
  StyledBusinessPreviewOasiForm,
  StyledBusinessPreviewOasiFormInputs,
  StyledBusinessPreviewOasiFormDragDropImage,
  StyledBusinessPreviewOasiLogoPlaceholder,
} from "./style.jsx";
import { StyledTextButtonBig } from "../globalstyle";

const BusinessPreviewOasiForm = ({
  logoPlaceholder, 
  logoRequiredError, 
  corporatePlaceholder, 
  corporateRequiredError, 
  emailPlaceholder, 
  button, 
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { uploadOasiPreviewImage } = useApi();
  const formRef = useRef(null);
  const { i18nOasiLink } = useContext(DataContext);
  const [formData, setFormData] = useState({
    name: null,
    email: null,
    imageUrl: null,
  });
  const onDrop = useCallback(acceptedFiles => {
    // Get only first file
    const file = acceptedFiles[0];
    
    setIsLoading(true);
    setIsError(false);
    uploadOasiPreviewImage(file).then((res) => {
      setIsLoading(false);
      if (res.error) {
        setIsError(true);
      } else {
        setFormData({
          ...formData,
          imageUrl: res.imageUrl, 
        });
      }
    });
  }, [formData, uploadOasiPreviewImage]);
  const {
    getRootProps, getInputProps, isDragActive, fileRejections, 
  } = useDropzone({
    onDrop,
    accept: "image/*",
  });
  const fileRejectionMessage = fileRejections.length > 0 ? fileRejections[0].errors[0].message : null;
  const goToPreview = () => {
    toast.configure();
      
    if(formRef.current && !formRef.current.reportValidity()) {
      return;
    }
    
    if (formData.imageUrl == null) {
      toast.error(logoRequiredError, {
        containerId: "logoRequiredError",
        position: toast.POSITION.TOP_CENTER, 
      });
      return;
    }

    if (formData.name == null) {
      toast.error(corporateRequiredError, {
        containerId: "corporateRequiredError",
        position: toast.POSITION.TOP_CENTER, 
      });
      return;
    }

    var url = i18nOasiLink(`/owner/preview/business?name=${formData.name}&image=${formData.imageUrl}`);
    if (formData.email != null) {
      url += `&email=${formData.email}`;
    }

    window.location.href = url; 
  };

  return (
    <StyledGridRow noMargin>
      <StyledBusinessPreviewOasiForm>
        <StyledBusinessPreviewOasiFormDragDropImage {...getRootProps()} isDragActive={isDragActive} isUploaded={formData.imageUrl != null}>
          <input {...getInputProps()} />
          {
            formData.imageUrl && !isDragActive
              ? <img src={formData.imageUrl} alt='Preview'/>
              :isLoading
                ? <img src="/2020/images/loading.gif" alt='Loading'/>
                : fileRejectionMessage && !isDragActive
                  ? <p>{fileRejectionMessage}</p>
                  : isError && !isDragActive
                    ? <p>Error, retry!</p>
                    : <StyledBusinessPreviewOasiLogoPlaceholder
                      dangerouslySetInnerHTML={{ __html: logoPlaceholder }}
                    />
          }
        </StyledBusinessPreviewOasiFormDragDropImage>

        <StyledForm ref={formRef}>
          <StyledBusinessPreviewOasiFormInputs>
            <StyledFormBlock>
              <TextInput
                id="preview-name"
                type="text"
                name="name"
                required
                placeholder={corporatePlaceholder}
                onInput={(e) =>
                  setFormData({
                    ...formData,
                    name: e.currentTarget.value, 
                  })
                }
              />
            </StyledFormBlock>

            <StyledFormBlock>
              <TextInput
                id="preview-email"
                type="email"
                name="email"
                placeholder={emailPlaceholder}
                onInput={(e) =>
                  setFormData({
                    ...formData,
                    email: e.currentTarget.value, 
                  })
                }
              />
            </StyledFormBlock>
          </StyledBusinessPreviewOasiFormInputs>
        </StyledForm>

        <Button onClick={goToPreview}>
          <StyledTextButtonBig dangerouslySetInnerHTML={{ __html: button }}/>
        </Button>
      </StyledBusinessPreviewOasiForm>
    </StyledGridRow>
  );
};

export default BusinessPreviewOasiForm;
